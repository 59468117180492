@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  /* --color-bg: #fdcbbf; */
  --color-bg: rgb(254, 244, 236);
  --color-bg-variant: #f9eadc;
  --color-primary: #3c3e41;
  --color-primary-variant: #fc9343;
  --color-white: #fff;

  --container-width-lg: 75%;
  --container-width-md: 85%;
  --container-width-sm: 90%;

  --transition: all 0.3s ease;
  --boxShadow-background: linear-gradient(145deg, #e2e8ec, #ffffff);
  --boxShadow: 4px 4px 8px #cbcbcb, 4px -4px #ffffff;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--color-bg);
  color: var(--color-primary);
  line-height: 1.7;
  -webkit-text-size-adjust: 100%;
}

/* ====================General Styles==================== */

.container {
  width: var(--container-width-sm);
  /* margin-bottom: 2rem; */
  margin: 0 auto;
  /* border: 1px solid red; */
}

h1 {
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  padding-top: 3rem;
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

h4 {
  font-size: 1.2rem;
  font-weight: bold;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.7rem;
}

p {
  font-size: 0.7rem;
}

li {
  font-size: 0.7rem;
  line-height: 1;
  list-style: circle;
  padding-top: 1rem;
}

a {
  transition: var(--transition);
  color:
  var(--color-primary);
}
 a:hover {
  color: var(--color-primary-variant);
 }

 button {
  outline: none;
  background-color: none;
}

.btn_shadow {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);

  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  transition: var(--transition);
  cursor: pointer;

  background: var(--boxShadow-background);
  box-shadow: var(--boxShadow);
}

.btn_shadow:hover {
  background: var(--color-primary-variant);
  color: var(--color-bg);
  border-color: transparent;
  transform: translateY(-10px);

}

img {
  display: block;
  max-width: 100%;
  max-height: 100%; 
  object-fit: cover;
}

.top {
  position: relative;
  top: -1rem;
}

/*----------Footer for iPad---------*/
@media (max-width: 1024px) {
  .containter {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/*----------Footer for phone---------*/
@media (max-width: 600px) {
  .containter {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 6rem;
  }
}