.footer_container {
    /* border: 1px solid var(--color-primary-variant); */
    margin: 2rem;
    text-align: center;
    /* padding: 1rem; */
}

.footer_logo {
    margin-top: 2rem;
    width: 5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;
}