.projects{
    margin-top: 2rem;
}
.projects_heading {
    text-align: center;
    margin-top: 0rem;
}

.projects_container {
    /* border: 1px solid red; */
    /* margin-left:2rem;
    margin-right: 5.5rem; */
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 2.5rem;
}

.projects_item {
    border: 1px solid var(--color-primary-variant);
    background-color: var(--color-white);
    padding: 1.2rem;
    border-radius: 1rem;
    transition: var(--transition);
}

.projects_item:hover {
    background-color: var(--color-primary-variant);
    color: var(--color-white);
    transition:var(--transition);
    transform: translate(0rem, -0.5rem);
}

.projects_item_details {
    justify-content: center;
    align-items: center;
}

.projects_item_img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 0.5rem;
    border-radius: 1rem;
    overflow: hidden;
    height: auto;
    max-height: 200px;
    width: auto;
    max-width: 250px;
}

.projects_item_title {
    margin-top: 0.5rem;
    text-align: center;
}

.projects_item_links {
    text-align: center;
    margin-bottom: 0.5rem;
}

.projects_btn {
    font-size: 0.6rem;
    font-weight: bold;
    border: 1px solid var(--color-primary);
    border-radius: 0.5rem;
    padding: 0.4rem;
    margin-bottom: 0rem;

}

.projects_btn:hover {
    background-color: var(--color-bg);
    color: var(--color-primary-variant);
    transition: var(--transition);
}

.pic_copyright {
    font-size: 0.5rem;
    font-style: italic;
    margin-right: 0;
}

.pic_copyright:hover {
    color: var(--color-white);
}

/************media queries*************/
@media screen and (max-width: 1024px) {
    .projects_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .projects_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}