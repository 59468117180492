.her {
    height: 100vh;
    padding-top: 1vh;
    overflow: hidden;
    /* border: 3px solid red; */
}

.logoImage {
    width: 100px;
    height: auto;
    margin-top: 2vh;
    margin-left: 5%;
    position: relative;
    opacity: 0.75;
}


.her_container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;    
    /* border: 2px solid rgb(241, 4, 4); */
}

/*-----------social media icons----------------*/
.socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    margin-top: 15%;
    padding: 0.5rem; 
}

.socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background-color:var(--color-primary);
    
}


.home_hovertext {
    position: relative;
  }
  
.home_hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    color: var(--color-primary-variant);
    text-align: right;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 0.3s ease-in-out;
  
    position: absolute;
    z-index: 1;
    left: 30px;
    top: -1%;
    font-size: 12px;
  }
  
.home_hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }

/*-----------my name----------------*/

.text_div {
    width: 100%;
    margin-top: 15%;
    margin-left: 10%;
    float:left;
    position: absolute;
}

h4 {
    color: var(--color-primary);
}

h2 {
    color: var(--color-primary);
    display: flex-inline;
    flex-direction: column;
}

.her_role {
    color: var(--color-primary-variant);
}

/*-----------my pic----------------*/

.her_pic {
    width: 30%;
    position: absolute;
    left: 58%;
    opacity: 90%;
    border-radius: 50px;
    /* background: linear-gradient(200deg, var(--color-bg), var(--color-bg-variant)); */
}


/*----------for iPad---------*/
@media (max-width: 1024px) {
    .her_container {
        padding-top: 0.5rem;
    }

    .text_div {
        margin-top: 40%;
        align-content: center;
    }

    .her_pic {
        width: 30%;
        left: 40%;
    }
}


/*----------for phone---------*/
@media (max-width: 600px) {

    .socials {
        display: none;
    }
}