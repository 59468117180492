/* .AboutMe {
    margin-top: -2rem;
    position: relative;
    border: solid 3px red;
} */

.aboutme_heading {
    text-align: center;
    top: -4rem;
}

/*--------------content----------------*/
.content {
    /* border: solid 3px red; */
    margin-top: 1rem;
    display:flex;
    width: 92%;
}
/*-----------left section------------*/
.left {
    position: relative;
    margin-left: 2rem;
    /* margin-right: 2rem; */
    width: 50%;
}

.left_title{
    margin-left: 2rem;
}

/*--------------the actual timeline line----------------*/
.timeline {
    position: relative;
    margin: 0 auto;
  }
/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: var(--color-primary-variant);
    top: 0;
    bottom: 0;
    left: -0.15rem;
  }

/*--------------the item in ----------------*/

/* Container around content */
.timeline_item_container {
    /* border: red solid 1px; */
    padding: 0.5rem 1rem;
    position: relative;
    border-radius: 6px;
    /* transform: translate(0, -0.5rem); */
    /* background-color: inherit; */
}

/* The circles on the timeline item*/
.timeline_item_container::after {
    content: '';
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    left:-0.5rem;
    background-color: var(--color-bg);
    border: 4px solid var(--color-primary-variant);
    top: 1.5rem;
    border-radius: 50%;
    z-index: 1;
  }
/* item */
.timeline_item {
    /* border: red solid 1px; */
    padding: 0.5rem 1rem;
    /* padding: 20px 30px; */
    background-color: white;
    position: relative;
    border-radius: 6px;
}

/* Add arrows to the left container (pointing right) */
.timeline_item::before {
    content: " ";
    height: 0;
    position: absolute;
    top:0.7rem;
    width: 0;
    z-index: 1;
    left: -0.5rem;
    border: medium solid var(--color-white);
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--color-white) transparent transparent;
  }

  /* hover effect for timeline_item */
.timeline_item_container:hover {
    /* color: var(--color-white); */
    background-color: var(--color-primary-variant);
    transition: 0.3s ease-in-out;
    /* transform: translate(0, -0.5rem); */
}

/* hr for desr*/
hr {
    /* margin: 30px 0 30px 0; */
    border: 2px solid rgb(218, 217, 217);
    background-color: #c2c4c5;
  }


/*----------Footer for iPad---------*/
/* @media (max-width: 1024px) {
    .ResumeBox {
        padding:0%;
    }

} */
/*----------Footer for phone---------*/
@media (max-width: 600px) {
    .ResumeBoxDesc {
        visibility: hidden;
    }
    .content_flex {
        flex-direction: column;
    }
} 
