nav {
    width:max-content;
    display: block;
    padding: 0.5rem 1rem;
    z-index: 2;
    position: fixed;
    right: 0;
    transform: translateX(-50%);
    display: flex;
    gap: 1rem;
    border-radius: 3rem;
    backdrop-filter: 15px;
    flex-direction: column;
    top: 10rem;
}

nav a {
    padding: 0.5rem; 
    display: flex;
    color: var(--color-primary);
    font-size: 1rem;
}

nav a:hover {
    color:var(--color-primary-variant)
}

.hovertext {
    position: relative;
  }
  
.hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    color: var(--color-primary-variant);
    text-align: left;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 0.5s ease-in-out;
  
    position: absolute;
    z-index: 1;
    right: 40px;
    top: -1%;
    font-size: 12px;
  }
  
.hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }


  