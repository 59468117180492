


/*------heading------`*/
.Contact {
    margin-top: 4rem;
}
.contact_heading {
    text-align: center;
}

/*-------left-------*/
.contact_left{
    /* border: 1px solid red; */
    width: 50%;
    
}

/*logo*/
.contact_logo {
    margin-top: 20%;
    margin-left: 15%;
    /* margin-left:calc(50%-75px); */
    width: 150px;
    height: 150px;
}

/*left_contact_details*/
.contact_details {
    margin-top: 20px;
    margin-left: 15%;
}

/*socialIcon*/
.contact_social_icon {
    margin-left: 1rem;
    /* padding: 2rem; */
}

.contact_social_hovertext {
    position: relative;
  }
  
.contact_social_hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    color: var(--color-primary-variant);
    text-align: right;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 0.3s ease-in-out;
  
    position: absolute;
    z-index: 1;
    left: -10px;
    top: 20px;
    font-size: 12px;
  }
  
.contact_social_hovertext:hover:before {
    opacity: 1;
    visibility: visible;
  }

/*-------right-------*/
.contact_right {
    width: 60%;
    /* border: 1px solid red; */
    /* padding: 1rem; */
    margin-top: 0rem;
}

.contact_form {
    margin-left: 2rem;
}

.contact_item {
    font-size: 1rem;
    /* width: 100%; */
    margin-bottom: 1.5rem;
}

.label_item {
    font-weight: bold;
    padding-right: 2rem;
}

.input_item {
    width: 100%;
    border: 1px solid var(--color-primary-variant);
    border-radius: 5px;
    padding: 0.5rem;
}

.message_item {
    width: 100%;
    height: 100px;
    border: 1px solid var(--color-primary-variant);
    border-radius: 5px;
    padding: 0.5rem;
}

.send_btn {
    border: 1px solid var(--color-primary-variant);
    color: var(--color-primary);
    background-color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 1rem;

}

.input_item:focus {
    border: 1px solid var(--color-primary);
    background-color: transparent;
}

.message_item:focus {
    border: 1px solid var(--color-primary);
    background-color: transparent;
}

.send_btn:hover {
    color: white;
    background-color: var(--color-primary-variant);
}


@media (max-width: 768px) {
    .Contact .d_flex {
      flex-direction: column;
    }
    .Contact .left,
    .Contact .right {
      width: 100%;
      margin-top: 50px;
    }
} 